<template>
  <b-row class="match-height">
    <b-col lg="6">
      <b-card title="Receive Purchase Order By Warehouse">
        <b-form @keyup.enter.prevent="" @submit.prevent="poCheck">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Receiving Number *"
                label-for="receiving_number"
                label-cols-md="4"
              >
                <b-form-input
                  id="receiving_number"
                  placeholder="Receiving Number"
                  required="required"
                  v-model="receivingNumber"
                />
              </b-form-group>
            </b-col>
            <!-- submit and reset -->
            <b-col offset-md="4">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BModal,
  BButton,
  VBModal,
  BCard,
} from "bootstrap-vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import axios from "axios"
import moment from "moment"

export default {
  components: {
    BModal,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      receivingNumber: '',
    }
  },
  methods: {
    poCheck() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const body = {
        receivingNumber: this.receivingNumber,
      }
      const url = `${process.env.VUE_APP_API_URL}transactions/purchase_order/pre/receive/warehouse/check`
      axios
        .post(url, body, { headers })
        .then(response => {
          if (response.data.success === true) {
            if (
              this.hasPermission("purchase_order_receive_warehouse") === true && response.data.data.po.purchase_order_is_received_by_warehouse === null && response.data.data.po.purchase_order_is_confirmed === true
            ) {
              localStorage.setItem("PurchaseOrderDetail", JSON.stringify(response.data.data.po))
              localStorage.setItem("PurchaseOrderReceive",JSON.stringify(response.data.data.poReceive))
              this.$router.push({
                name: "apps-purchase-order-receive-warehouse",
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "You Do not have permission to do this",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              })
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Invoice Number Invalid!",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    },
    dateFormat(value, format = "MM/DD/YYYY") {
      return moment(String(value)).format(format)
    },
    hasPermission(permission) {
      const permissions = this.$cookies.get("userPermissions").split(",")
      return permissions.includes(permission) ? true : false
    },
  },
}
</script>
